'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function ($) {

	// Global vars
	var errorMessage = 'This feature requires options in order to work, please check the documention.';

	/**
  * This function checks whether or not the options parameter has been filled out
  * if it hasn't, then the callback becomes the options
  *
  * @param {string} options - the options for each function
  * @param {Function} callback - the callback itself
  * @method checkCallback
  * @private
  */
	var checkCallback = function checkCallback(options, callback) {
		if ($.isFunction(options)) {
			callback = options;
		}

		return callback;
	};

	/**
  * This function checks whether options are required. If they are it checks if
  * the options are present. If not it returns false
  *
  * @param  {string} options - the options for the function
  * @param  {boolean} optionsRequired - whether options are required or not
  * @return {boolean} true or false based on conditionals
  * @method checkOptions
  * @private
  */
	var checkOptions = function checkOptions(options, optionsRequired) {
		var optionsValue = (typeof options === 'undefined' ? 'undefined' : _typeof(options)) === 'object' ? $.isEmptyObject(options) === false : options !== undefined;

		if (optionsRequired && optionsValue) {
			return true;
		} else if (optionsRequired === false) {
			return true;
		}
	};

	/**
  * This function watches for onload and scroll events. If fires optionsChecked() to check
  * if the options parameter are needed and if so are present. It also fires the checkCallback
  * function to make sure that the callback var is set to a function (ie if there are no options
  * when called the callback var will be empty). It When events are triggered it checks if the
  * target is in view. If it is then if fires the function
  *
  * @param {object} options - the options for the function
  * @param {Function} callback - the function for a callback
  * @method inView
  * @private
  */
	$.fn.inView = function (options, callback) {
		var optionsChecked = checkOptions(options, false),
		    updatedCallback = optionsChecked ? checkCallback(options, callback) : false,
		    callbackIsValid = typeof updatedCallback === 'function',
		    self = this;

		/**
   * This function checks that the callback is indeed a function. When scrolling or on load the function
   * gets the current window position and the targets (self) current position. It also checks if there
   * is the options of buffer available. It then sets the trigger point to be the position minus the buffer.
   * It also checks to see if the function has already been run. If the option 'fireAfter' is available it
   * checks if the element is below the trigger point AND hasn't already fired. If so it triggers the function.
   * Else if the element is in the correcnt position AND hasn't already fired, the function is triggered
   *
   * @method inViewEffect
   * @private
   */
		var inViewEffect = function inViewEffect() {
			var $currentScrollPosition = $(window).scrollTop(),
			    $windowHeight = $(window).height(),
			    topTargetPosition = self.offset().top,
			    bottomTargetPosition = self.outerHeight() + topTargetPosition,
			    bufferAmount = typeof options === 'function' ? 0 : options[Object.keys(options)[0]],
			    buffer = $windowHeight * bufferAmount,
			    topTriggerPoint = topTargetPosition - buffer,
			    alreadyRun = self.attr('data-functioncomplete') == '1',
			    fireAfter = options[Object.keys(options)[1]] === undefined ? false : options[Object.keys(options)[1]];

			if (optionsChecked) {
				if (callbackIsValid) {
					if (fireAfter && $currentScrollPosition >= topTriggerPoint && !alreadyRun) {
						self.attr('data-functioncomplete', '1');
						updatedCallback.call(self);
					}
					if ($currentScrollPosition >= topTriggerPoint && $currentScrollPosition <= bottomTargetPosition && !alreadyRun) {
						self.attr('data-functioncomplete', '1');
						updatedCallback.call(self);
					}
				}
			} else {
				console.error(errorMessage); // eslint-disable-line no-console
			}
		};

		/**
   * This feature watches for on load and scroll events. When done the inViewEffect is fired
   *
   * @method triggerInView
   * @private
   */
		(function triggerInView() {
			$(window).on('load', function () {
				inViewEffect();
			});
			$(window).on('scroll', function () {
				inViewEffect();
			});
		})();
	};

	/**
  * This function watches for load and resize events. When triggered the code checks
  * if the trigger is minWidth, maxWidth or both. It then fires callbacks based on
  * whether the viewport is the correct width
  *
  * @param  {object} options - the widths to trigger on
  * @param  {Function} callback - the callback for the minWidth
  * @param  {Function} callback2 - the callback for the maxWidth
  * @param  {Function} callback3 - the callback for the minWidth and maxWidth
  * @method breakpoint
  */
	$.fn.breakpoint = function (options, callback, callback2, callback3) {
		var optionsChecked = checkOptions(options, true),
		    minTrigger = options.minWidth,
		    hasMin = minTrigger !== undefined,
		    maxTrigger = options.maxWidth,
		    hasMax = maxTrigger !== undefined,
		    minOnly = hasMin && !hasMax,
		    maxOnly = !hasMin && hasMax,
		    callbackIsValid = typeof callback === 'function',
		    callback2IsValid = typeof callback === 'function',
		    callback3IsValid = typeof callback === 'function',
		    self = this;

		/**
   * This function checks the trigger is min, max or both. It then fires the correct callbacks
   * based on this info
   *
   * @method breakpointEffect
   * @private
   */
		var breakpointEffect = function breakpointEffect() {
			var currentWindowSize = $('html').hasClass('no-touchevents') ? $(window).innerWidth() : $(window).outerWidth();

			if (optionsChecked) {
				if (callbackIsValid) {
					if (minOnly) {
						if (currentWindowSize >= minTrigger) {
							callback.call(self);
						} else if (callback2IsValid) {
							callback2.call(self);
						}
					} else if (maxOnly) {
						if (currentWindowSize <= maxTrigger) {
							callback.call(self);
						} else if (callback2IsValid) {
							callback2.call(self);
						}
					} else {
						if (currentWindowSize >= minTrigger && currentWindowSize <= maxTrigger) {
							// eslint-disable-line no-lonely-if
							callback.call(self);
						} else if (currentWindowSize >= minTrigger) {
							if (callback2IsValid) {
								callback2.call(self);
							}
						} else if (currentWindowSize <= maxTrigger) {
							if (callback3IsValid) {
								callback3.call(self);
							}
						}
					}
				}
			} else {
				console.error(errorMessage); // eslint-disable-line no-console
			}
		};

		/**
   * This feature watches for on load and scroll events. When done the breakpointEffect() is fired
   *
   * @method triggerBreakpoint
   * @private
   */
		(function triggerBreakpoint() {
			$(window).on('load', function () {
				breakpointEffect();
			});
			$(window).on('resize', function () {
				breakpointEffect();
			});
		})();
	};

	/**
  * This function adds an event listener for orientation changes. When orientations is changed
  * it checks if it matches the required orientations and fires functions based on the check.
  *
  * @param  {string} options - the orientation
  * @param  {Function} callback - function to fire on the orientation above
  * @param  {[type]} callback2 - function to fire on opposite orientations
  * @method onOrientation
  * @private
  */
	$.fn.onOrientation = function (options, callback, callback2) {
		var optionsChecked = checkOptions(options, true),
		    optionValue = options,
		    callbackIsValid = typeof callback === 'function',
		    self = this;

		/**
   * This function checks what the orientation is. If it matches the value in the options
   * if fires the related function. Otherwise it checks if the second value is a function
   * and then fires it
   *
   * @method onOrientations
   * @private
   */
		var onOrientationEffect = function onOrientationEffect() {
			var orientationAngle = screen.orientation === undefined ? window.orientation : screen.orientation.angle,
			    currentOrientation = orientationAngle === 90 ? 'landscape' : 'portrait';

			if (optionsChecked) {
				if (callbackIsValid) {
					if (currentOrientation === optionValue) {
						callback.call(self);
					} else if (typeof callback2 === 'function') {
						callback2.call(self);
					}
				}
			} else {
				console.error(errorMessage); // eslint-disable-line no-console
			}
		};

		/**
   * This adds an event listener for orinetations. It then fires the onOrientationEffect
   * when a change happens;
   *
   * @method triggerInView
   * @private
   */
		(function triggetOnOrientation() {
			window.addEventListener('orientationchange', function () {
				onOrientationEffect();
			}, false);
		})();
	};

	/**
  * This function checks the current window size and based on the width, returns a true or false
  * of the device. ie, if you pass 'tablet' as your option and the width is bigger than the mobile
  * breakpoint and equal or less than the tablet it returns true
  *
  * @param  {sting} - the device to check against
  * @return {Boolean} - true or false based on the checks
  */
	$.isDevice = function (options) {
		var optionsChecked = checkOptions(options, true),
		    optionValue = options,
		    mobileBreakpoint = 640,
		    tabletBreakpoint = 768,
		    checkResult,
		    currentWindowSize;

		var checkDevice = function checkDevice() {
			if (optionsChecked) {
				currentWindowSize = $('html').hasClass('no-touchevents') ? $(window).outerWidth() - 17 : $(window).outerWidth();

				if (optionValue === 'mobile') {
					if (currentWindowSize <= mobileBreakpoint) {
						checkResult = true;
					} else {
						checkResult = false;
					}
				} else if (optionValue === 'tablet') {
					if (currentWindowSize > mobileBreakpoint && currentWindowSize <= tabletBreakpoint) {
						checkResult = true;
					} else if (currentWindowSize > tabletBreakpoint && $('html').hasClass('touchevents')) {
						checkResult = true;
					} else {
						checkResult = false;
					}
				} else if (optionValue === 'desktop') {
					if (currentWindowSize > tabletBreakpoint) {
						checkResult = true;
					} else {
						checkResult = false;
					}
				}

				return checkResult;
			} else {
				// eslint-disable-line no-else-return
				console.error(errorMessage); // eslint-disable-line no-console
			}
		};

		checkResult = checkDevice();

		return checkResult;
	};

	/**
  * This function adds an event listener for animation or transition end to the target. If the no
  * options are passed through it adds the event listener for both animation and transition
  *
  * @param  {string} options - the type of event you want to watch
  * @param  {Function} callback - the function to fire when event is complete
  * @method cssEventsComplete
  * @private
  */
	$.fn.cssEventsComplete = function (options, callback) {
		var optionsChecked = checkOptions(options, false),
		    noOptions = typeof options === 'function',
		    eventToWatch,
		    target,
		    updatedCallback = optionsChecked ? checkCallback(options, callback) : false,
		    callbackIsValid = typeof updatedCallback === 'function',
		    self = this;

		/**
   * This function first makes a check on the option and the callback. It then sets the target to add the watch
   * to. It then checks the option and adds the correct event listener. It then binds the event listener to the
   * target and fires the function when the listener is triggered
   *
   * @method cssEventsCompleteCheck
   */
		var cssEventsCompleteCheck = function cssEventsCompleteCheck() {
			if (optionsChecked) {
				if (callbackIsValid) {
					target = self[0];

					if (noOptions) {
						eventToWatch = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd';
					} else if (options == 'transition') {
						eventToWatch = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd';
					} else if (options == 'animation') {
						eventToWatch = 'animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd';
					}

					$(target).bind(eventToWatch, function () {
						updatedCallback.call(self);
					});
				} else {
					console.error(errorMessage); // eslint-disable-line no-console
				}
			}
		};

		/**
   * This function fires the cssEventsCompleteCheck function on document ready
   *
   * @method triggerCssEventsComplete
   * @private
   */
		(function triggerCssEventsComplete() {
			$(document).ready(function () {
				cssEventsCompleteCheck();
			});
		})();
	};

	/**
  * This function gets all the details from the query string in the url and splits up content
  * into default divider, custom divider and hash. This content is then all added to an object
  * which is returned to the user
  *
  * @param  {string} options - the custom divider
  * @return {object} queryStingResults -  all the results added as properties to the object
  * @private
  */
	$.queryString = function (options) {
		var optionsChecked = checkOptions(options, false),
		    querystring = window.location.search.slice(1),
		    hashValues = window.location.hash.substr(1),
		    optionValue = options,
		    queryStingResults,
		    completeQueryContent = {},
		    defaultDivider = '&',
		    customDivider = optionValue === undefined ? false : optionValue,
		    defaultParts = querystring.indexOf(defaultDivider) > 0 ? querystring.split(defaultDivider) : 0;

		/**
   * This function loops around all the tags split up by the default divider. It removes any content that
   * comes after the custom divider and pushes the part to defaultPartList which is attached to the completeQueryContent
   * object. If there is no divider at all it just returns the original query
   *
   * @method attachDefaultParts
   * @private
   */
		var attachDefaultParts = function attachDefaultParts() {
			var i = 0,
			    thisDefaultPart,
			    updatedDefaultPart,
			    defaultPartList = [],
			    $hasNoDivider = querystring.indexOf(defaultDivider) <= 0 && querystring.indexOf(customDivider) < 0;

			if (defaultParts.length > 0) {
				for (i; i < defaultParts.length; i++) {
					thisDefaultPart = defaultParts[i];
					updatedDefaultPart = thisDefaultPart.indexOf(customDivider) > 0 ? thisDefaultPart.substr(0, thisDefaultPart.indexOf(customDivider)) : thisDefaultPart;

					defaultPartList.push(updatedDefaultPart);
				}
				completeQueryContent.defaultdivider = defaultPartList;
			} else if ($hasNoDivider) {
				completeQueryContent.defaultdivider = [querystring];
			}
		};

		/**
   * This function loops through all the custom tags (providing that the customDivider has been set and
   * that it is present in the query string). It then removes any content after a default divider and
   * pushes the content to customPartList. It then attaches the content to the completeQueryContent
   *
   * @method attachCustomerParts
   * @private
   */
		var attachCustomParts = function attachCustomParts() {
			var i = 0,
			    thisCustomPart,
			    updatedCustomerPart,
			    customParts,
			    customPartList = [],
			    updatedQuerystring;

			if (customDivider && querystring.indexOf(customDivider) > 0) {
				updatedQuerystring = querystring.indexOf(defaultDivider) > 0 ? querystring.substr(querystring.indexOf(customDivider) + 1, querystring.length) : querystring;
				customParts = updatedQuerystring.split(customDivider);

				for (i; i < customParts.length; i++) {
					thisCustomPart = customParts[i];
					updatedCustomerPart = thisCustomPart.indexOf(defaultDivider) > 0 ? thisCustomPart.substr(0, thisCustomPart.indexOf(defaultDivider)) : thisCustomPart;

					customPartList.push(updatedCustomerPart);
				}
				completeQueryContent.customdivider = customPartList;
			}
		};

		/**
   * This function checks if there is a hash and attaches it to the completeQueryContent object
   *
   * @method attachHashParts;
   * @private
   */
		var attachHashParts = function attachHashParts() {
			if (hashValues.length > 0) {
				completeQueryContent.hashvalue = hashValues;
			}
		};

		/**
   * This function checks if the options have been filled out and if there is a querystring at all.
   * If there is it fires attachDefaultParts, attachCustomParts and attachHashParts then returns the
   * pdated object
   *
   * @return {object} - the object updeated with all the query content
   */
		var getQueryString = function getQueryString() {
			if (optionsChecked) {
				if (querystring) {
					attachDefaultParts();
					attachCustomParts();
					attachHashParts();

					return completeQueryContent;
				}
			} else {
				console.error(errorMessage); // eslint-disable-line no-console
			}
		};

		queryStingResults = getQueryString();

		return queryStingResults;
	};

	/**
  * This function add custom events to google analytics based on the options passed in.
  *
  * @param  {object} options - the details to be added to the ga event
  * @method gACustomEvents
  */
	$.gACustomEvents = function (options) {
		var optionsChecked = checkOptions(options, false);

		var gACustomEventsSendEvent = function gACustomEventsSendEvent() {
			var categoryName, categoryAction, categoryLabel;

			if (optionsChecked) {
				categoryName = options.category;
				categoryAction = options.action;
				categoryLabel = options.label;

				ga('send', {
					hitType: 'event',
					eventCategory: categoryName,
					eventAction: categoryAction,
					eventLabel: categoryLabel
				});
			} else {
				console.error(errorMessage); // eslint-disable-line no-console
			}
		};

		/**
   * This feature watches for on load and scroll events. When done the gACustomEventsSendEvent is fired
   *
   * @method triggerGoogleAnalytics
   * @private
   */
		(function triggerGoogleAnalytics() {
			gACustomEventsSendEvent();
		})();
	};
})(jQuery);
/**
 * This contains the core code for all effects
 * @module ALIBABA
 */
var ALIBABA = function (module, $) {
	/**
  * addActive function
  *
  * @method addActive
  * @private
  */
	module.addActive = function () {
		// Global vars
		var addActiveClassName = 'js-add-active',
		    $addActiveItems = $('.' + addActiveClassName),
		    activeClass = 'active';

		/**
   * This function loops round each element and after a delay, it adds the active
   * class.
   *
   * @param  {array} $allElements - array of all elements in row
   * @method staggerAddClass
   * @private
   */
		var staggerAddClass = function staggerAddClass($thisTarget, $childItems, $staggerDelay) {
			var delayAmount = 200 + parseInt($staggerDelay);

			$thisTarget.inView({ buffer: 0.8, fireafter: true }, function () {
				$childItems.each(function (index) {
					setTimeout(function ($this) {
						$this.addClass(activeClass);
					}, delayAmount * index, $(this));
				});
			});
		};

		/**
   * Bind events.
   * Checks what event to bind and what element to bind it to based on
   * data attributes. If the event is inview, it will use the existing
   * checks to apply the active class.
   *
   * @method bindEvents
   * @private
   */
		var bindEvents = function bindEvents() {
			$addActiveItems.each(function (i, elem) {
				var $elementToAnimate = $(elem),
				    animationData = $elementToAnimate.data('animation'),
				    ev = animationData.animateEvent,
				    triggerElemClassName = animationData.animateElem,
				    delayBreakpoint = animationData.delayBreakpoint,
				    mediaQueryCondition = animationData.delayBreakpointDirection == 'down' ? window.innerWidth <= delayBreakpoint : window.innerWidth >= delayBreakpoint;

				if (ev === 'inview') {
					checkAddActiveTarget($elementToAnimate);
				} else {
					$('.' + triggerElemClassName).on(ev, function () {
						if (mediaQueryCondition) {
							setTimeout(function () {
								$elementToAnimate.addClass(activeClass);
							}, animationData.delayAmount);
						} else {
							$elementToAnimate.addClass(activeClass);
						}
					});
				}
			});
		};

		/**
   * This function fires the inView function. When the target is in view it adds the activeClass to the
   * target
   *
   * @param {object} $thisTarget - the element to watch and update
   * @method addActiveClass
   * @private
   */
		var addActiveClass = function addActiveClass($thisTarget) {
			$thisTarget.inView({ buffer: 0.8, fireafter: true }, function () {
				$(this).addClass(activeClass);
			});
		};

		/**
   * This function does a number of check, first to find out if it
   * the addActive class should be target the children or not. It then checks if it needs to stagger the children.
   * If the target is the children AND it needs to stagger them it gets the direct children of the item and
   * fires the staggerAddClass function. If it needs to target the children ONLY it gets the direct children of
   * the item and loops around all the children fires the addActiveClass for each. If the target is only this
   * Item then it fires the addActiveClass for the item only
   *
   * @param {object} $elem - the element to be animated
   * @method checkAddActiveTarget
   */
		var checkAddActiveTarget = function checkAddActiveTarget($elem) {
			var $thisItem = void 0,
			    $staggerItems = void 0,
			    $targetChildernInstead = void 0,
			    $childItems = void 0,
			    $thisChildItem = void 0,
			    $staggerDelay = void 0;

			$thisItem = $elem;
			$staggerItems = $thisItem.attr('data-stagger-addactive') == 'true';
			$targetChildernInstead = $thisItem.attr('data-targetchildren') == 'true';

			if ($staggerItems && $targetChildernInstead) {
				$staggerDelay = $thisItem.attr('data-stagger-delay') ? $thisItem.attr('data-stagger-delay') : 0;

				$childItems = $thisItem.children().not('[data-exclude-addactive="true"]');

				staggerAddClass($thisItem, $childItems, $staggerDelay);
			} else if ($targetChildernInstead) {
				$childItems = $thisItem.children().not('[data-exclude-addactive="true"]');

				$childItems.each(function (index, el) {
					$thisChildItem = $(el);

					addActiveClass($thisChildItem);
				});
			} else {
				addActiveClass($thisItem);
			}
		};

		/**
   * Fires addActiveClass function if there are any elements on the page
   *
   * @method init
   * @private
   */
		(function init() {
			if ($addActiveItems.length > 0) {
				bindEvents();
			}
		})();
	}();

	return module;
}(ALIBABA || {}, jQuery);
/**
 * This contains the core code for all effects
 * @module ALIBABA
 */
var ALIBABA = function (module, $, Cookies) {
	/**
  * Functions for the cookie nag panel
  *
  * @main cookieConsent
  */
	module.cookieConsent = function () {
		/**
   *
   *
   * @method cookieConsentSetup
   * @private
   */
		var cookieConsentSetup = function cookieConsentSetup() {
			var cookieConsent = Cookies.get('ALIBABACookieConsent');

			if (cookieConsent !== 'accepted') {
				$('.cookie-consent').addClass('fixed');
			} else {
				$('.cookie-consent').remove();
			}

			$('.cookie-consent-accept').on('click', function (ev) {
				ev.preventDefault();

				Cookies.set('ALIBABACookieConsent', 'accepted', { expires: 365 });
				$('.cookie-consent').fadeOut(500);
			});
		};

		/**
   * This fires all functions for pagination
   *
   * @method init
   * @private
   */
		(function init() {
			cookieConsentSetup();
		})();
	}();

	return module;
}(ALIBABA || {}, jQuery, Cookies);
/**
 * This is a really simple parallax module that wraps the Rellax library:
 * https://github.com/dixonandmoe/rellax
 *
 * Its main purpose is to automatically add parallax to DOM nodes that
 * have the class "js-rellax".
 *
 * Data attributes are used to configure the parallax effect for each element:
 *
 * data-rellax-speed="5"         (speed element scrolls relative to rest of page)
 * data-rellax-center="true"     (centers the parallax effect to the viewport)
 * data-rellax-round="true"      (rounds decimal positional values to integer values)
 * data-rellax-vertical="true"   (enables parallax for vertical scrolling)
 * data-rellax-horizontal="true" (enables parallax for horizontal scrolling)
 * data-rellax-zindex="5"        (set z-index - useful for overlapping parallaxed elements)
 *
 * It also exposes helper methods to manually create and destroy a parallax
 * instance on an element. In this use case, a configuration object can optionally
 * be passed to the create method in lieu of using data attributes.
 */
var ALIBABA = function (module, Rellax, $) {

	module.parallax = function () {
		var breakPoint = 768,
		    $isAboveBreakpoint = $(window).width() >= breakPoint,
		    activeClass = 'active-parallax',
		    allParallaxObjects = [];

		/**
   * This function loops around all parallax objects and destroys them and then empties the array.
   *
   * @method destoryAll
   * @private
   */
		var destoryAll = function destoryAll() {
			for (var i = 0; i < allParallaxObjects.length; i++) {
				var thisParallaxItem = allParallaxObjects[i];

				thisParallaxItem.destroy();
			}

			allParallaxObjects = [];
		};

		/**
   * This function first loops around each checks if the condition is create or destroy. It also checks if the item is already active. If its not
   * active and the condition is create it triggers Rellax for this item, adds the activeClass and pushes the item to the allParallaxObjects array
   * if the condition is destroy, it removes the active class. Outside of the loop it does a check if the condition is destroy, if so it fires destoyAll
   *
   * @param {string} condition - whether to destroy or create the parallax
   * @method triggerParallax
   * @private
   */
		var triggerParallax = function triggerParallax(condition) {
			$('.js-rellax').each(function () {
				var node = $(this)[0];
				var notTriggered = $(this).hasClass(activeClass) == false;

				if (condition == 'create' && notTriggered) {
					var target = new Rellax(node);

					$(this).addClass(activeClass);

					allParallaxObjects.push(target);
				} else if (condition == 'destroy') {
					$(this).removeClass(activeClass);
				}
			});

			if (condition == 'destroy') {
				destoryAll();
			}
		};

		/**
   * This function checks if the window is above the breakpoint. If so it fires triggerParallax. It also watches for resize. Each resize it
   * updates the $isAboveBreakpoint var and then fires triggerParallax with different conditions based on that boolean
   *
   * @method init
   * @private
   */
		var init = function init() {
			var $windowWidth = $(window).width();

			if ($isAboveBreakpoint) {
				$(window).load(function () {
					triggerParallax('create');
				});
			}

			$(window).resize(function () {
				if ($(window).width() != $windowWidth) {
					$windowWidth = $(window).width();

					$isAboveBreakpoint = $(window).width() >= breakPoint;

					if ($isAboveBreakpoint) {
						triggerParallax('create');
					} else {
						triggerParallax('destroy');
					}
				}
			});
		};

		/**
   *
   * @param {string} selector CSS selector
   * @param {object} options optional config object
   * @returns {object} Rellax instance
   */
		var create = function create(selector, options) {
			return new Rellax(selector, options);
		};

		/**
   * Destroys the passed in Rellax instance
   * @param {object} instance Rellax instance
   */
		var destroy = function destroy(instance) {
			return instance.destroy();
		};

		/**
   * Initialize
   */
		$(document).ready(init);

		return { create: create, destroy: destroy };
	}();

	return module;
}(ALIBABA || {}, Rellax, jQuery);

/**
 * This contains the core code for all effects
 * @module ALIBABA
 */
var ALIBABA = function (module, $) {
	/**
 * this function watches for clicks on the mobNav elements and adds classes to the body and
 * elements in the instance
 *
 * @method mobNav
 * @private
 */
	module.mobNav = function () {

		// Global vars
		var $header = $('.js-main-header'),
		    $navInstances = $('.js-main-nav-wrapper'),
		    navActiveClass = 'nav-active',
		    openClass = 'nav-open',
		    hoverClass = 'hover',
		    $theBody = $('body'),
		    $navAccordions = $('.js-nav-accordion'),
		    triggeredClass = 'nav-accordion-open',
		    activeClass = 'nav-accordion-active',
		    $isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

		/**
   * This function checks if the nav is already active. If not it works out the screen height
   * and the current screen position of the target and works out how much space is left. That
   * space is then set to the targets height. If it is active it turns the height to 0
   *
   * @param {array} $thisInstanceTarget - the target on which to act
   * @param {boolean} $isActive - whether its active or not
   * @method setHeight
   * @private
   */
		var setHeight = function setHeight($thisInstanceTarget, $isActive) {
			var $currentWindowHeight = $(window).outerHeight(),
			    $currentInstancePosition = $($thisInstanceTarget)[0].getBoundingClientRect().top,
			    newHeight = $currentWindowHeight - $currentInstancePosition;

			if (!$isActive) {
				$thisInstanceTarget.css('height', newHeight + 'px');
			} else {
				$thisInstanceTarget.css('height', '0');
			}
		};

		/**
   * This function adds the offset for the target position
   *
   * @param {array} $thisInstanceTarget - the target of the instance
   * @method setOffset
   * @private
   */
		var setOffset = function setOffset($thisInstanceTarget) {
			var $headerHeight = $header.outerHeight();

			$thisInstanceTarget.css('top', $headerHeight);
		};

		/**
   * This function finds and active nav items and removes any active/open classes as well as
   * removing any style atrributes that had been dynamically added
   *
   * @param {object} $thisInstance - this nav
   * @method closeNavItems
   * @private
   */
		var closeNavItems = function closeNavItems($thisInstance) {
			var $allActive = $($thisInstance).find('.' + navActiveClass),
			    i = $allActive.length - 1;

			for (i; i >= 0; i--) {
				var $thisActive = $($allActive[i]),
				    $thisActiveChild = $thisActive.find('.' + openClass);

				$thisActive.removeClass(navActiveClass).removeAttr('style');
				$thisActiveChild.removeClass(openClass).removeAttr('style');
			}
		};

		/**
   * This function:
   *  - removes classes associated to mobile nav from body, nav and burger icon
   *  - fires CloseNavItems
   *  - fires SetHeight if the nav type is set to dropdown
   *
   * @param {object} $thisInstance - this nav
   * @param {array} $thisInstanceTarget - the target on which to act
   * @param {boolean} $isActive - whether its active or not
   * @method closeNav
   * @private
   */
		var closeNav = function closeNav($thisInstance, $thisInstanceTarget, $isActive) {
			var $navType = $($thisInstance).attr('data-nav-type'),
			    $thisInstanceTrigger = $($thisInstance).find('.js-main-nav-trigger');

			$theBody.removeClass(navActiveClass).removeClass($navType).css('top', '0');
			$thisInstanceTrigger.removeClass(navActiveClass);
			$thisInstanceTarget.removeClass(navActiveClass);

			closeNavItems($thisInstance);

			if ($navType == 'dropdown') {
				setHeight($thisInstanceTarget, $isActive);
			}
		};

		/**
   * This function first finds all the nav items with a subnav. It then loops around all subnav items and checks if
   * the user is on a tablet in landscape mode. If not it watches for hover via jQuery, removes the hoverClass from
   * any active items and then adds the hoverClass to itself. On hover out it removes the class. If the tablet is landscape
   * it gets the active item, checks if the current item is already open. If not it stops it from moving to a new page
   * removes the hoverClass from the active item and then adds the hoverClass itself
   *
   * @param {boolean} $isTablet - if it is a tablet or not
   * @param {object} $thisInstanceTarget - the instance's target
   * @method addHoverClass
   * @private
   */
		var addHoverClass = function addHoverClass($isTablet, $thisInstanceTarget) {
			var $thisInstanceTargetLiWithChild = $($thisInstanceTarget).find('> ul > li:has(ul)'),
			    activeItem = $thisInstanceTargetLiWithChild.filter('.' + hoverClass),
			    $tabletLandscape = $isTablet && $(window).outerWidth() > 768;

			for (var i = $thisInstanceTargetLiWithChild.length - 1; i >= 0; i--) {
				var thisInstance = $thisInstanceTargetLiWithChild[i];

				if ($tabletLandscape) {
					$(thisInstance).on('click', function (event) {
						// eslint-disable-line no-loop-func
						activeItem = $thisInstanceTargetLiWithChild.filter('.' + hoverClass);

						if (!$(this).hasClass(hoverClass)) {
							event.preventDefault();
							$(activeItem).removeClass(hoverClass);
							$(this).addClass(hoverClass);
						}
					});
				}
			}
		};

		/**
  * This function checks to see if there is an orientation change and fires closeNav
  * if the mobile navigation is active
  *
  * @param {array} $thisInstanceTarget - the target on which to act
  * @method watchOrientationChange
  * @private
  */
		var watchOrientationChange = function watchOrientationChange($thisInstance, $thisInstanceTarget) {
			var $thisInstanceTargetLiWithChild = $($thisInstanceTarget).find('> ul > li:has(ul)');

			$(window).onOrientation('portrait', function () {
				var $isActive = $thisInstanceTarget.hasClass(navActiveClass);

				if ($isActive) {
					closeNav($thisInstance, $thisInstanceTarget, $isActive);
				}

				$thisInstanceTargetLiWithChild.removeClass(hoverClass);
			}, function () {
				// now in landscape  viewport
				var $isActive = $thisInstanceTarget.hasClass(navActiveClass);

				if ($isActive) {
					closeNav($thisInstance, $thisInstanceTarget, $isActive);
				}

				$thisInstanceTargetLiWithChild.removeClass(hoverClass);
			});
		};

		/**
   * This function watches for clicks on the trigger. It checks if the item is already active. If not, it adds
   * the active class to the body, the instance and the trigger. It also adds the current scroll position as 'top' to the body so that
   * the page doesn't jump when the body is set to fixed. If it is active, it removes if from the same
   * elements and returns the top top 0 then scrolls to that position so the page doesn't return to the top
   *
   * @param  {array} $thisInstanceTrigger - the trigger for the instance
   * @param  {array} $thisInstance - the instance
   * @param  {array} $thisInstanceTarget - the target for the instance
   * @method mobNavEffect
   * @private
   */
		var mobNavEffect = function mobNavEffect($thisInstanceTrigger, $thisInstance, $thisInstanceTarget) {

			$thisInstanceTrigger.on('click', function () {
				var $isActive = $thisInstanceTarget.hasClass(navActiveClass),
				    $currentScrollPosition = $(document).scrollTop(),
				    $navType = $($thisInstance).attr('data-nav-type');

				// need to reset the height as the page might have been loaded in the non mobile resolution
				if ($navType == 'dropdown') {
					setOffset($thisInstanceTarget);
				}

				if (!$isActive) {
					$theBody.removeClass('no-transition').addClass(navActiveClass).addClass($navType).css('top', '-' + $currentScrollPosition + 'px');
					$thisInstanceTarget.addClass(navActiveClass);
					$thisInstanceTrigger.addClass(navActiveClass);

					if ($navType == 'dropdown') {
						setHeight($thisInstanceTarget, $isActive);
					}
				} else {
					closeNav($thisInstance, $thisInstanceTarget, $isActive);
				}
			});
		};

		/**
   * This function watches for checks on resize. When resized it checks if the browser is wider than
   * then breakpoint AND is active. If so, it removes the navActiveClass from the body and resets its top
   * position. It then removes styles and classes from the instance. Finally, it loops around all items
   * with the class active, finds any children that are open then removes classes and styles from both
   *
   * @param  {array} $thisInstanceInstance - the instance
   * @param  {array} $thisInstanceTarget - the target for the instance
   * @method resetNav
   * @private
   */
		var resetNav = function resetNav($thisInstance, $thisInstanceTarget) {
			var breakpoint = 992;

			$(window).resize(function () {
				var $currentWindowSize = $(window).outerWidth(),
				    $currentlyActive = $thisInstanceTarget.hasClass(navActiveClass),
				    $allActive = $($thisInstance).find('.' + navActiveClass),
				    i = $allActive.length - 1;

				if ($currentWindowSize > breakpoint && $currentlyActive) {
					$theBody.addClass('no-transition').removeClass(navActiveClass).css('top', '0').delay(500).queue(function () {
						$(this).removeClass('no-transition').dequeue();
					});

					$($thisInstance).removeClass(navActiveClass).removeAttr('style');

					for (i; i >= 0; i--) {
						var $thisActive = $($allActive[i]),
						    $thisActiveChild = $thisActive.find('.' + openClass);

						$thisActive.removeClass(navActiveClass).removeAttr('style');
						$thisActiveChild.removeClass(openClass).removeAttr('style');
					}
				}
			});
		};

		/**
   * This function finds all the li's in the main navigation and removes the hover event
   *
   * @param  {object} $thisInstanceTarget - this instance target
   * @method unbindHover
   * @private
   */
		var unbindHover = function unbindHover($thisInstanceTarget) {
			var $navItems = $thisInstanceTarget.children('ul').children('li');

			$navItems.unbind('mouseenter mouseleave');
		};

		/**
   * This function adds a class to the body if side-slide nav is set in the data attr.
   *
   * @param  {array} $thisInstance - this instance
   * @method addBodyClassWhenSideSlide
   * @private
   */
		var addBodyClassWhenSideSlide = function addBodyClassWhenSideSlide($thisInstance) {
			var $navType = $($thisInstance).attr('data-nav-type');

			if ($navType == 'side-slide') {
				$theBody.addClass('side-slide-nav');
			}
		};

		/**
   * This function loops around all nav instances, gets the elements and checks what type of nav it is
   * via a data-attr. If its side-slide then if fires the sideslide function, otherwise the dropdown function. It checks
   * if the user is on a tablet. If so it fires unbindHover function. It then checks if the nav is only for mobile
   * it fires resetNav
   *
   * @method triggerNav
   * @private
   */
		var triggerNav = function triggerNav() {
			var i = $navInstances.length - 1,
			    $isTablet = $(window).outerWidth() >= 768 && $(window).outerWidth() <= 1024 && $('html').hasClass('touchevents');

			for (i; i >= 0; i--) {
				var $thisInstance = $navInstances[i],
				    $navType = $($thisInstance).attr('data-nav-type'),
				    $mobileOnly = $($thisInstance).attr('data-mobileonly'),
				    $thisInstanceTrigger = $($thisInstance).find('.js-main-nav-trigger'),
				    $thisInstanceTarget = $($thisInstance).find('.js-main-nav');

				mobNavEffect($thisInstanceTrigger, $thisInstance, $thisInstanceTarget, $navType);
				addHoverClass($isTablet, $thisInstanceTarget);
				watchOrientationChange($thisInstance, $thisInstanceTarget);
				addBodyClassWhenSideSlide($thisInstance);

				if ($isTablet) {
					unbindHover($thisInstanceTarget);
				}

				if ($mobileOnly) {
					resetNav($thisInstance, $thisInstanceTarget);
				}
			}
		};

		/**
  * this function checks if the accordion content is already open, if so
  * slideup the content and remove the class. If its not then slidedown the
  * content and add the class. If its been disabled and the device is iOS
  * then the content doesn't slide, it just appears
  *
  * @method accordionEffect
  * @param  {object} $thisContent - the content to be opened or closed
  * @param {jQuery} $thisTriggerParent - the partent of the trigger
  * @param {string} allMultipleOpen - whether multiple contents are allowed to be open
  * @param {boolen} $isDisabled - whether the slide has been disabled
  * @private
  */
		var accordionEffect = function accordionEffect($thisContent, $thisTriggerParent, allMultipleOpen, $isDisabled) {
			if ($thisContent.hasClass(triggeredClass)) {
				$thisContent.slideUp().removeClass(triggeredClass);

				if ($isIos && $isDisabled) {
					$thisContent.css('display', 'none').removeClass(triggeredClass);
				} else {
					$thisContent.slideUp().removeClass(triggeredClass);
				}
			} else {
				if (allMultipleOpen === 'false') {
					$thisTriggerParent.siblings().find('.' + triggeredClass).slideUp().removeClass(triggeredClass);
				}

				if ($isIos && $isDisabled) {
					$thisContent.css('display', 'block').addClass(triggeredClass);
				} else {
					$thisContent.slideDown().addClass(triggeredClass);
				}
			}
		};

		/**
  * this function adds the active class if its not already on the item
  * and also updates the class on the chevron.
  * If it is it removes active from itself and any active children as well
  * as update the chevron on all children
  *
  * @param {object} $thisTrigger - the item that has been clicked
  * @param {object} $thisTriggerParents - the parent of the item that might be active
  * @param {object} $activeTriggers - all active triggers
  * @param {object} $activeTriggerIcons - all active icons for the triggers
  * @param {boolen} - whether it has the class or not
  * @method updateActive
  * @private
  */
		var updateActive = function updateActive($thisTrigger, $thisTriggerParent, isActive) {

			var gtmAction = $thisTriggerParent.attr('data-interactive-component-label'),
			    gtmActionAlt = $thisTriggerParent.attr('data-interactive-component-label-alt');

			if (isActive) {
				$thisTriggerParent.removeClass(activeClass);
				$thisTrigger.removeClass(activeClass);
			} else {
				$thisTriggerParent.addClass(activeClass);
				$thisTrigger.addClass(activeClass);
			}

			if (gtmAction == gtmActionAlt) {
				$thisTriggerParent.attr('data-interactive-component-complete', true);
			} else {
				$thisTriggerParent.attr('data-interactive-component-label', gtmActionAlt);
			}
		};

		/**
  * this function watches for click events on the accordion triggers.
  * Then the parent li is found and is used to search for all children
  * going forward. If the trigger item has open items below, then all
  * open items are passed as the content that needs to be closed. This
  * ensures that all items are closed when top parent is clicked. If trigger
  * items has active class or items below have active class, then all active
  * items are passed as $activeTriggers. This ensures all active items are
  * updated when parent is clicked.
  * It then fires updateActive() and accordionEffect() with the correct content,
  * then checkExpandAll() to check if the expand all needs to be updated
  *
  * @method triggerAccordion
  * @private
  */
		var triggerAccordion = function triggerAccordion($thisAccordionTriggers) {
			$thisAccordionTriggers.on('click', function (event) {
				var $thisTrigger = $(this),
				    $thisTriggerParent = $thisTrigger.parents('.js-nav-accordion-block'),
				    $thisMainContainer = $thisTrigger.parents('.js-nav-accordion'),
				    $isDisabled = $thisMainContainer.attr('data-disable-iphone'),
				    allMultipleOpen = $thisMainContainer.attr('data-multiple-open'),
				    $openChilden = $thisTriggerParent.find('.' + triggeredClass),
				    hasOpenChilden = $openChilden.length > 0,
				    $thisContent = hasOpenChilden ? $openChilden : $thisTriggerParent.find('> .js-nav-accordion-content'),
				    $activeChildren = $thisTriggerParent.find('.' + activeClass),
				    isActive = $activeChildren.length > 0 || $thisTriggerParent.hasClass(activeClass);

				event.stopPropagation();

				updateActive($thisTrigger, $thisTriggerParent, isActive);
				accordionEffect($thisContent, $thisTriggerParent, allMultipleOpen, $isDisabled);
			});
		};

		/**
   * This fires all functions for reveal more
   *
   * @method init
   * @private
   */
		(function init() {
			if ($navInstances.length > 0) {
				triggerNav();
			}

			if ($navAccordions.length > 0) {
				for (var i = 0; i < $navAccordions.length; i++) {
					var $thisAccordion = $navAccordions.eq(i),
					    $thisAccordionTriggers = $thisAccordion.find('.js-nav-accordion-trigger');

					triggerAccordion($thisAccordionTriggers);
				}
			}
		})();
	}();

	return module;
}(ALIBABA || {}, jQuery);
/**
 * This contains the core code for all effects
 * @module ALIBABA
 */
var ALIBABA = function (module, $) {
	/**
 * example function
 *
 * @method example
 * @private
 */
	module.accordion = function () {

		// Global vars
		var $accordions = $('.js-accordion'),
		    triggeredClass = 'open-accordion',
		    activeClass = 'active-accordion',
		    updateAllButton = $('.js-accordion-updateall'),
		    $isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

		/**
  * this function checks if the accordion content is already open, if so
  * slideup the content and remove the class. If its not then slidedown the
  * content and add the class. If its been disabled and the device is iOS
  * then the content doesn't slide, it just appears
  *
  * @method accordionEffect
  * @param  {object} $thisContent - the content to be opened or closed
  * @param {jQuery} $thisTriggerParent - the parent of the trigger
  * @param {string} allMultipleOpen - whether multiple contents are allowed to be open
  * @param {boolen} $isDisabled - whether the slide has been disabled
  * @private
  */
		var accordionEffect = function accordionEffect($thisContent, $thisTriggerParent, allMultipleOpen, $isDisabled) {
			if ($thisContent.hasClass(triggeredClass)) {
				$thisContent.slideUp().removeClass(triggeredClass);

				if ($isIos && $isDisabled) {
					$thisContent.css('display', 'none').removeClass(triggeredClass);
				} else {
					$thisContent.slideUp().removeClass(triggeredClass);
				}
			} else {
				if (allMultipleOpen === 'false') {
					$thisTriggerParent.siblings().find('.' + triggeredClass).slideUp().removeClass(triggeredClass);
				}

				if ($isIos && $isDisabled) {
					$thisContent.css('display', 'block').addClass(triggeredClass);
				} else {
					$thisContent.slideDown().addClass(triggeredClass);
				}
			}
		};

		/**
  * this function adds the active class if its not already on the item
  * and also updates the class on the chevron.
  * If it is it removes active from itself and any active children as well
  * as update the chevron on all children
  *
  * @param {object} $thisTrigger - the item that has been clicked
  * @param {object} $thisTriggerParents - the parent of the item that might be active
  * @param {object} $activeTriggers - all active triggers
  * @param {object} $activeTriggerIcons - all active icons for the triggers
  * @param {boolen} - whether it has the class or not
  * @method updateActive
  * @private
  */
		var updateActive = function updateActive($thisTrigger, $thisTriggerParent, isActive, allMultipleOpen, $thisAccordionTriggers) {

			var gtmAction = $thisTriggerParent.attr('data-interactive-component-label'),
			    gtmActionAlt = $thisTriggerParent.attr('data-interactive-component-label-alt');

			if (isActive) {
				$thisTriggerParent.removeClass(activeClass);
				$thisTrigger.removeClass(activeClass);
			} else {
				if (allMultipleOpen === 'false') {
					var $activeTrigger = $thisAccordionTriggers.filter('.' + activeClass),
					    $activeTriggerParent = $activeTrigger.parent();

					$activeTrigger.removeClass(activeClass);
					$activeTriggerParent.removeClass(activeClass);
				}

				$thisTriggerParent.addClass(activeClass);
				$thisTrigger.addClass(activeClass);
			}

			if (gtmAction == gtmActionAlt) {
				$thisTriggerParent.attr('data-interactive-component-complete', true);
			} else {
				$thisTriggerParent.attr('data-interactive-component-label', gtmActionAlt);
			}
		};

		/**
   * This function checks if the number of open accordions matches the number of available
   * accordions. If it does then it updates the expand all button text and adds the class.
   * If there is one less than the number (ie you closed one when all accordions were open),
   * it updates the text and class for the expand all button
   *
   * @param  {object} $thisMainContainer - the main container for this accordion
   * @method
   * @private
   */
		var checkExpandAll = function checkExpandAll($thisMainContainer) {
			var numberOfAccordions = $thisMainContainer.find('.js-accordion-block').length,
			    $allOpen = $thisMainContainer.find('.' + activeClass + '.js-accordion-block').length,
			    thisExpandAll = $thisMainContainer.find('.js-accordion-updateall');

			if ($allOpen === numberOfAccordions) {
				thisExpandAll.addClass(activeClass).text('Close All');
			} else if ($allOpen === numberOfAccordions - 1) {
				thisExpandAll.removeClass(activeClass).text('Expand All');
			}
		};

		/**
  * this function watches for click events on the accordion triggers.
  * Then the parent li is found and is used to search for all children
  * going forward. If the trigger item has open items below, then all
  * open items are passed as the content that needs to be closed. This
  * ensures that all items are closed when top parent is clicked. If trigger
  * items has active class or items below have active class, then all active
  * items are passed as $activeTriggers. This ensures all active items are
  * updated when parent is clicked.
  * It then fires updateActive() and accordionEffect() with the correct content,
  * then checkExpandAll() to check if the expand all needs to be updated
  *
  * @method triggerAccordion
  * @private
  */
		var triggerAccordion = function triggerAccordion($thisAccordionTriggers) {
			$thisAccordionTriggers.on('click', function (event) {
				var $thisTrigger = $(this),
				    $thisTriggerParent = $thisTrigger.parents('.js-accordion-block'),
				    $thisMainContainer = $thisTrigger.parents('.js-accordion'),
				    $isDisabled = $thisMainContainer.attr('data-disable-iphone'),
				    allMultipleOpen = $thisMainContainer.attr('data-multiple-open'),
				    $openChilden = $thisTriggerParent.find('.' + triggeredClass),
				    hasOpenChilden = $openChilden.length > 0,
				    $thisContent = hasOpenChilden ? $openChilden : $thisTriggerParent.find('> .js-accordion-content'),
				    $activeChildren = $thisTriggerParent.find('.' + activeClass),
				    isActive = $activeChildren.length > 0 || $thisTriggerParent.hasClass(activeClass);

				event.stopPropagation();

				updateActive($thisTrigger, $thisTriggerParent, isActive, allMultipleOpen, $thisAccordionTriggers);
				accordionEffect($thisContent, $thisTriggerParent, allMultipleOpen, $isDisabled);
				checkExpandAll($thisMainContainer);
			});
		};

		/**
   * This function gets all the elements with default-open class and checks if the accordion
   * is for mobile only. If the accordion is only for mobile then the function stops, if
   * not it goes through each item and clicks the accordion trigger
   *
   * @method openByDefault
   * @private
   */
		var openByDefault = function openByDefault() {
			var defaultOpenItems = $('.js-accordion-default-open');

			defaultOpenItems.each(function () {
				var $thisItem = $(this),
				    $thisTrigger = $thisItem.find('.js-accordion-block-trigger'),
				    $thisContent = $thisItem.find('.js-accordion-content'),
				    $thisParent = $thisItem.parent('.js-accordion'),
				    $thisParentSetting = $thisParent.attr('data-mobileonly'),
				    onlyMobile = $thisParentSetting === undefined ? false : $thisParentSetting;

				if (!onlyMobile) {
					$thisItem.addClass(activeClass);
					$thisContent.show().addClass(triggeredClass);
					$thisTrigger.removeClass(activeClass);
				}
			});
		};

		/**
   * This function watches for clicks on the closeAllButton. When clicked it
   * checks if the element is already been triggered. If so update the text
   * and add the class active.
   *
   * @method updateAll
   * @private
   */
		var updateAll = function updateAll() {
			updateAllButton.on('click', function () {
				var $thisTrigger = $(this),
				    $isActive = $thisTrigger.hasClass(activeClass),
				    $allElements = $('.js-accordion-block'),
				    $allOpenElements = void 0,
				    $allClosedElements = void 0,
				    $thisOpenTrigger = void 0,
				    $thisClosedTrigger = void 0;

				if ($isActive) {
					$allOpenElements = $allElements.filter('.' + activeClass);
					$thisTrigger.removeClass(activeClass).text('Expand All');

					$allOpenElements.each(function () {
						$thisOpenTrigger = $(this).find('.js-accordion-block-trigger');

						$thisOpenTrigger.click();
					});
				} else {
					$allClosedElements = $allElements.not('.' + activeClass);
					$thisTrigger.addClass(activeClass).text('Close All');

					$allClosedElements.each(function () {
						$thisClosedTrigger = $(this).find('.js-accordion-block-trigger');

						$thisClosedTrigger.click();
					});
				}
			});
		};

		/**
   * This function refires triggerAccodion with newAccordionTriggers passed in. This is public for accordions dynamically added
   *
   * @param {array} newAccordionTriggers
   * @method registerAccordion
   * @public
   */
		var registerAccordion = function registerAccordion(newAccordionTriggers) {
			triggerAccordion(newAccordionTriggers);
		};

		/**
   * Fires accordion functions
   *
   * @method init
   * @private
   */
		(function init() {
			if ($accordions.length > 0) {
				for (var i = 0; i < $accordions.length; i++) {
					var $thisAccordion = $accordions.eq(i),
					    $thisAccordionTriggers = $thisAccordion.find('.js-accordion-block-trigger');

					triggerAccordion($thisAccordionTriggers);
					openByDefault();
					updateAll();
				}
			}
		})();

		return {
			registerAccordion: registerAccordion
		};
	}();

	return module;
}(ALIBABA || {}, jQuery);
